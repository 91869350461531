import React, { useState, useEffect, useReducer } from "react";

import Page from "../../components/Page";
import SEO from "../../components/SEO";
import { Slider } from "../../components/Slider";

import PackageConditionsFile from "../../assets/PackageConditions.pdf";

import { advertisers } from "../../helpers/navigation";

import { getAdvertisersSolution } from "../../api/advertisersSolution";

const constants = {
  FULL: "FULL",
  ASIDE: "ASIDE",
};

const initialState = { name: constants.FULL };

const reducer = (state, action) => {
  switch (action.type) {
    case constants.FULL:
      return { name: constants.FULL, image: action.payload };
    case constants.ASIDE:
      return { name: constants.ASIDE, image: action.payload };
    default:
      throw new Error();
  }
};

const SuggestionsPage = () => {
  const [promotion, setPromotion] = useState("");
  const [tariffConditionsStates, settariffConditionsStates] = useState({});
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const fetchData = async () => {
      const {
        promotionText,
        tariffConditions,
      } = await getAdvertisersSolution();

      setPromotion(promotionText);

      let tfState = {};
      tariffConditions.forEach((element) => {
        tfState[element.imageName] = element.image;
      });

      settariffConditionsStates(tfState);

      dispatch({
        type: constants.FULL,
        payload: tfState.FULL.url,
      });
    };

    fetchData();
  }, []);

  const slides = [
    <div className="Suggestions-variant">
      <div className="Suggestions-variant_map">
        <table className="variant-table">
          <tbody>
            <tr>
              <td
                className="main-zone"
                style={{
                  width: 130,
                  minWidth: 130,
                  height: 60,
                }}
              >
                Основна <br /> зона
              </td>
              <td
                style={{
                  width: 40,
                  minWidth: 40,
                  height: 60,
                  borderLeft: "none",
                }}
              ></td>
            </tr>
            <tr>
              <td colSpan="2"></td>
            </tr>
          </tbody>
        </table>
      </div>
      <span className="Suggestions-variant_desc">
        1) Демонстрації в основній зоні та повноекранні.
        <br />
        Розмір 1920x720
      </span>
    </div>,
    <div className="Suggestions-variant">
      <div className="Suggestions-variant_map">
        <table className="variant-table">
          <tbody>
            <tr>
              <td
                style={{
                  width: 130,
                  minWidth: 130,
                  height: 60,
                  borderRight: "none",
                }}
              ></td>
              <td
                className="main-zone"
                style={{
                  maxWidth: 40,
                  minWidth: 40,
                  height: 60,
                }}
              >
                <span
                  style={{
                    display: "block",
                    transform: "rotate(270deg)",
                  }}
                >
                  Бічна
                </span>
              </td>
            </tr>
            <tr>
              <td colSpan="2"></td>
            </tr>
          </tbody>
        </table>
      </div>
      <span className="Suggestions-variant_desc">
        2) Демонстрації в бічній зоні.
        <br />
        Розмір 400x855
      </span>
    </div>,
  ];

  const toogleSlider = () => {
    switch (state.name) {
      case constants.FULL:
        dispatch({
          type: constants.ASIDE,
          payload: tariffConditionsStates[constants.ASIDE].url,
        });
        break;

      case constants.ASIDE:
        dispatch({
          type: constants.FULL,
          payload: tariffConditionsStates[constants.FULL].url,
        });
        break;

      default:
        break;
    }
  };

  return (
    <Page
      page="Suggestions"
      pageName="Пропозиції"
      title="Пропозиції"
      parameters={advertisers}
    >
      <SEO title="Пропозиції" />

      {promotion && <div className="Suggestions__promotion">{promotion}</div>}

      <Slider slides={slides} arrows arrowsFunc={toogleSlider} />

      <div className="Suggestions__info">
        <h6 className="Suggestions__info_title">Тарифні умови</h6>

        {Object.keys(tariffConditionsStates).length > 0 && (
          <img
            src={state.image}
            alt={state.image}
            className="Suggestions__info_table"
          />
        )}
        <span className="Suggestions__info_mark">
          Орієнтовні дані для демонстрації 15 сек. ролика на 1-й Точці виводу на
          протязі місяця (без ПДВ)
        </span>
        <br />
        <div className="Suggestions__info_more">
          <a href={PackageConditionsFile} target="_blank" rel="noreferrer">
            Дізнатись детальніше...
          </a>
        </div>
      </div>
    </Page>
  );
};

export default SuggestionsPage;
