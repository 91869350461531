import { API_URL, POST_OPTIONS } from "./api.constants";

const query = `
    query {
      promotions {
        promotionText
      }
      tariffConditions {
        imageName
        image {
          url
        }
      }
    }
  `;

const opts = {
  ...POST_OPTIONS,
  body: JSON.stringify({ query }),
};

const getAdvertisersSolution = async () => {
  let result = await fetch(API_URL, opts)
    .then((res) => res.json())
    .then((data) => {
      const promotionText =
        data.data.promotions.length > 0
          ? data.data.promotions[0].promotionText
          : "";

      return {
        promotionText,
        tariffConditions: data.data.tariffConditions,
      };
    })
    .catch((err) => {
      console.log(err);
      return err;
    });

  return result;
};

export { getAdvertisersSolution };
